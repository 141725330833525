import React from 'react';
import PropTypes from 'prop-types';

function CircleIcon({ className }) {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      className={className}
      imageRendering="auto"
      baseProfile="basic"
      version="1.1"
      preserveAspectRatio="xMidYMid meet"
      viewBox="25 25 50 50"
    >
      <circle
        className="loader-path"
        cx="50"
        cy="50"
        r="20"
        fill="none"
        stroke="#000"
        strokeWidth="2"
      />
    </svg>
  );
}

CircleIcon.propTypes = {
  className: PropTypes.string
};

CircleIcon.defaultProps = {
  className: ''
};

export default CircleIcon;
