import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Circle from '../../assets/icons/Circle';
import styles from './Loader.module.scss';

const Loader = ({ className, loading }) => {
  return (
    <div className={cx(styles.loaderWrapper, className, loading ? styles.loading : '')}>
      <Circle className={styles.circle} />
    </div>
  );
};

Loader.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool
};

Loader.defaultProps = {
  className: '',
  loading: true
};

export default Loader;
